import React, { useEffect, useRef, useState } from "react";
import { Alert, Col, Container, Row } from "reactstrap";
import deLocale from "@fullcalendar/core/locales/de";
import { getFirebaseBackend } from "helpers/firebase_helper";
import Loading from "components/Loading";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import NewReservationModal from "./NewReservationModal";
import EditReservationModal from "./EditReservationModal";
import "flatpickr/dist/themes/material_blue.css";
import { Austria } from "flatpickr/dist/l10n/at.js";
import Flatpickr from "react-flatpickr";
import moment from "moment";

const CalendarOverview = () => {
  const calendarRef = useRef();
  const fireBaseBackend = getFirebaseBackend();
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [error, setError] = useState("");
  const [selectedReservation, setSelectedReservation] = useState({});
  const [newResModal, setNewResModal] = useState(false);
  const [editResModal, setEditResModal] = useState(false);
  const [company, setCompany] = useState({});
  const scrollTime = new Date();

  useEffect(() => {
    loadCompany();
  }, []);

  const loadCompany = async _ => {
    scrollTime.setHours(scrollTime.getHours() - 1);
    const c = await fireBaseBackend.getCompany();
    setCompany(c);
    setLoading(false);
  };

  const handleDrop = async info => {
    const result = confirm(
      info.event.title +
        " wird verschoben auf " +
        info.event.start.toLocaleString()
    );

    if (!result) info.revert();
  };

  const handelClick = info => {
    setSelectedReservation({
      date: new Date(info.dateStr),
      table: info.resource._resource.id,
    });
    setNewResModal(true);
  };

  const handleEventClick = info => {
    setSelectedReservation(
      company.reservations.find(r => r.id == info.event._def.publicId)
    );
    setEditResModal(true);
  };

  const handleEventChange = info => {
    setLoading(true);
    const start = info.event.start;
    const end = info.event.end;
    var duration = Math.abs(end - start) / 36e5;
    const current = company.reservations.find(
      r => r.id == info.event._def.publicId
    );

    fireBaseBackend
      .updateReservations({
        ...current,
        date: start,
        duration: duration,
        table: info.event._def.resourceIds[0],
      })
      .then(async _ => {
        await loadCompany();
        setLoading(false);
        alert("Speichern erfolgreich!");
      }, handleError);
  };

  const toggleModals = async () => {
    setNewResModal(false);
    setEditResModal(false);

    setLoading(true);
    await loadCompany();
    setLoading(false);
  };

  const handleError = async error => {
    setError(error);
    await loadCompany();
    setLoading(false);
    setTimeout(() => {
      setError();
    }, 3000);
  };

  const addHours = (date, hours) => {
    const myDate = new Date(date);
    myDate.setTime(myDate.getTime() + hours * 60 * 60 * 1000);
    return myDate;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {error && <Alert color="danger">{error}</Alert>}
          <Loading isLoading={loading}>
            <Row className="mb-2">
              <Col lg={9}></Col>
              <Col lg={3}>
                <Flatpickr
                  className="form-control d-block"
                  placeholder="dd.mm.yyyy"
                  onChange={e => {
                    setSelectedDate(e);
                    calendarRef.current?.calendar.gotoDate(e[0]);
                  }}
                  value={selectedDate}
                  options={{
                    dateFormat: "d.m.y",
                    minDate: "today",
                    locale: {
                      ...Austria,
                      firstDayOfWeek: 1,
                    },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <FullCalendar
                ref={calendarRef}
                plugins={[interactionPlugin, resourceTimelinePlugin]}
                initialView="resourceTimelineDay"
                handleWindowResize={true}
                themeSystem="bootstrap"
                events={company.reservations?.map(r => {
                  return {
                    id: r.id,
                    title: r.name + " | " + r.persons + " P. | " + r.notes,
                    start: r.date.toDate(),
                    end: addHours(r.date.toDate(), r.duration),
                    resourceId: r.table,
                  };
                })}
                resources={company.tables?.map(r => {
                  return { id: r.name, name: r.name };
                })}
                editable={true}
                selectable={true}
                locale={deLocale}
                resourceAreaHeaderContent="Tische"
                resourceAreaWidth="10%"
                slotDuration="00:15:00"
                slotMinTime="07:00:00"
                slotMaxTime="22:00:00"
                nowIndicator={true}
                scrollTime={moment(scrollTime).format("HH") + ":00:00"}
                eventDrop={handleDrop}
                dateClick={handelClick}
                eventClick={handleEventClick}
                eventChange={handleEventChange}
              />
              <NewReservationModal
                isOpen={newResModal}
                toggle={toggleModals}
                company={company}
                selectedReservation={selectedReservation}
              />
              <EditReservationModal
                isOpen={editResModal}
                toggle={toggleModals}
                company={company}
                selectedReservation={selectedReservation}
              />
            </Row>
          </Loading>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CalendarOverview;
