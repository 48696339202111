import React, { useState } from "react";
import { Alert, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { getFirebaseBackend } from "helpers/firebase_helper";
import "flatpickr/dist/themes/material_blue.css";
import Loading from "components/Loading";
import DeleteModal from "components/Common/DeleteModal";

const EditReservationModal = ({ isOpen, toggle, selectedReservation }) => {
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [error, setError] = useState("");
  const [countPersons, setCountPersons] = useState(
    selectedReservation?.persons ?? 2
  );
  const fireBaseBackend = getFirebaseBackend();

  const handleSubmit = async values => {
    setLoading(true);
    console.log("öalsdkf");
    fireBaseBackend
      .updateReservations({
        ...selectedReservation,
        name: values.name,
        notes: values.notes,
        persons: countPersons,
      })
      .then(handleSuccess, handleError);
  };

  const handleSuccess = _ => {
    toggleModal();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleError = error => {
    setError(error);
    setLoading(false);
    setTimeout(() => {
      setError();
    }, 3000);
  };

  const toggleModal = () => {
    if (isOpen) {
      setTimeout(() => {
        setCountPersons(2);
      }, 1000);
    }
    toggle();
  };

  const handleDeleteReservation = async () => {
    setDeleteModal(false);
    setLoading(true);
    await fireBaseBackend.deleteReservation(selectedReservation);
    toggle();
    setLoading(false);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggleModal}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleModal}>Reservierung Details</ModalHeader>
          <ModalBody>
            <Loading isLoading={loading}>
              {error && <Alert color="danger">{error}</Alert>}
              <Formik
                enableReinitialize={true}
                initialValues={{
                  name: selectedReservation?.name ?? "",
                  notes: selectedReservation?.notes ?? "",
                  duration: selectedReservation?.duration ?? 1,
                  persons: selectedReservation?.persons ?? 2,
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Bitte geben Sie Ihren Namen an"),
                })}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form className="form-horizontal">
                    <div className="mb-3">
                      <Label for="persons" className="form-label">
                        Wie viele Personen?
                      </Label>
                      <Field
                        name="persons"
                        type="number"
                        onChange={e => {
                          setCountPersons(e.target.value);
                        }}
                        value={countPersons}
                        className={
                          "form-control" +
                          (errors.persons && touched.persons
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="persons"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="name" className="form-label">
                        Name
                      </Label>
                      <Field
                        name="name"
                        type="text"
                        className={
                          "form-control" +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="notes" className="form-label">
                        Notizen
                      </Label>
                      <Field
                        name="notes"
                        type="text"
                        className={
                          "form-control" +
                          (errors.notes && touched.notes ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="notes"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mt-4 d-grid">
                      <button
                        className="btn btn-danger btn-block"
                        type="button"
                        onClick={_ => setDeleteModal(true)}
                      >
                        Löschen
                      </button>
                    </div>
                    <div className="mt-2 d-grid">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        Speichern
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Loading>
          </ModalBody>
        </div>
      </Modal>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteReservation}
        onCloseClick={toggleDeleteModal}
      />
    </React.Fragment>
  );
};

export default EditReservationModal;
