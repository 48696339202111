import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

//import Charts

//import action

// Pages Components
import WelcomeComp from "./WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import CardUser from "./CardUser";
import { getFirebaseBackend } from "helpers/firebase_helper";
import Loading from "components/Loading";

const Dashboard = () => {
  const fireBaseBackend = getFirebaseBackend();
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [company, setCompany] = useState();

  const togglemodal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    (async () => {
      setCompany(await fireBaseBackend.getCompany());
      setLoading(false);
    })();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashoard" breadcrumbItem="Übersicht" />
          <Loading isLoading={loading}>
            <Row>
              <Col xl="4">
                <WelcomeComp company={company} />
              </Col>
              <Col xl="8">
                <Card>
                  <CardBody>
                    <CardUser company={company} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Loading>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default Dashboard;
