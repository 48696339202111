import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Input,
  CardTitle,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import { Austria } from "flatpickr/dist/l10n/at.js";
import Flatpickr from "react-flatpickr";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import Loading from "components/Loading";
import { getFirebaseBackend } from "helpers/firebase_helper";

const UserProfile = props => {
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(true);
  const [vacationDates, setVacationDates] = useState("");
  const [company, setCompany] = useState();
  const [logoURL, setLogoURL] = useState();
  const fireBaseBackend = getFirebaseBackend();

  useEffect(() => {
    (async () => {
      const c = await fireBaseBackend.getCompany();
      setCompany(c);
      setLoading(false);
      setLogoURL(c.logo);
      setVacationDates(c.vacation?.map(v => v.toDate()));
    })();
  }, []);

  const handleLogoChange = async event => {
    setLoading(true);
    const newFile = event.target.files[0];
    setFile(newFile);
    const url = await fireBaseBackend.uploadLogo(newFile, company.name);
    setLogoURL(url);
    setLoading(false);
  };

  const handleSubmit = async values => {
    setLoading(true);
    company.logo = logoURL;
    company.color = values.color;
    company.name = values.name;
    company.website = values.website;
    company.background = values.background;
    await fireBaseBackend.updateCompany(company);

    setLoading(false);
  };

  const handleOpeningHoursSubmit = async values => {
    setLoading(true);
    company.openingHours[0] = values.montag;
    company.openingHours[1] = values.dienstag;
    company.openingHours[2] = values.mittwoch;
    company.openingHours[3] = values.donnerstag;
    company.openingHours[4] = values.freitag;
    company.openingHours[5] = values.samstag;
    company.openingHours[6] = values.sonntag;
    company.vacation = vacationDates;
    await fireBaseBackend.updateCompany(company);

    setLoading(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="JUST4BOOKING" breadcrumbItem="Profil" />
          <Loading isLoading={loading}>
            <Row>
              <Col lg="12">
                {props.error && props.error ? (
                  <Alert color="danger">{props.error}</Alert>
                ) : null}
                {props.success && props.success ? (
                  <Alert color="success">{props.success}</Alert>
                ) : null}
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="me-3">
                        <div className="avatar-md">
                          <span className="avatar-title rounded-circle bg-light overflow-hidden">
                            <img
                              src={logoURL ?? company?.logo ?? avatar}
                              alt=""
                              style={{ "object-fit": "cover" }}
                              className="w-100 h-100"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          <h5>{company?.name}</h5>
                          <p className="mb-1">{company?.email}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Einstellungen</h4>

            <Row>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        name: company?.name ?? "",
                        color: company?.color ?? "",
                        background: company?.background ?? "",
                        website: company?.website ?? "",
                        logo: company?.logo ?? "",
                      }}
                      validationSchema={Yup.object().shape({})}
                      onSubmit={handleSubmit}
                    >
                      {({ errors, touched }) => (
                        <Form className="form-horizontal">
                          <div className="mb-3">
                            <Label for="name" className="form-label">
                              Firmenname
                            </Label>
                            <Field
                              name="name"
                              type="text"
                              className={
                                "form-control" +
                                (errors.name && touched.name
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3">
                            <Label for="website" className="form-label">
                              Webseite
                            </Label>
                            <Field
                              name="website"
                              type="text"
                              className={
                                "form-control" +
                                (errors.website && touched.website
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="website"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formFile" className="form-label">
                              Logo
                            </Label>
                            <Input
                              className="form-control"
                              type="file"
                              id="formFile"
                              accept="/image/*"
                              onChange={handleLogoChange}
                            />
                          </div>
                          <div className="mb-3">
                            <Label for="color" className="form-label">
                              Schriftfarbe (hex)
                            </Label>
                            <Field
                              name="color"
                              type="text"
                              className={
                                "form-control" +
                                (errors.color && touched.color
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="color"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3">
                            <Label for="background" className="form-label">
                              Hintergrundfarbe (hex)
                            </Label>
                            <Field
                              name="background"
                              type="text"
                              className={
                                "form-control" +
                                (errors.background && touched.background
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="background"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="text-center mt-4">
                            <Button type="submit" color="primary">
                              Speichern
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Öffnungszeiten</CardTitle>

                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        montag: company?.openingHours[0] ?? "",
                        dienstag: company?.openingHours[1] ?? "",
                        mittwoch: company?.openingHours[2] ?? "",
                        donnerstag: company?.openingHours[3] ?? "",
                        freitag: company?.openingHours[4] ?? "",
                        samstag: company?.openingHours[5] ?? "",
                        sonntag: company?.openingHours[6] ?? "",
                        vacation: company?.vacation ?? "",
                      }}
                      validationSchema={Yup.object().shape({})}
                      onSubmit={handleOpeningHoursSubmit}
                    >
                      {({ errors, touched }) => (
                        <Form className="form-horizontal">
                          <div className="mb-3 row">
                            <Label
                              for="montag"
                              className="col-form-label col-sm-3"
                            >
                              Montag
                            </Label>
                            <Col sm={9}>
                              <Field
                                name="montag"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.montag && touched.montag
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            </Col>
                            <ErrorMessage
                              name="montag"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3 row">
                            <Label
                              for="dienstag"
                              className="col-form-label col-sm-3"
                            >
                              Dienstag
                            </Label>
                            <Col sm={9}>
                              <Field
                                name="dienstag"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.dienstag && touched.dienstag
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            </Col>
                            <ErrorMessage
                              name="dienstag"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3 row">
                            <Label
                              for="mittwoch"
                              className="col-form-label col-sm-3"
                            >
                              Mittwoch
                            </Label>
                            <Col sm={9}>
                              <Field
                                name="mittwoch"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.mittwoch && touched.mittwoch
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            </Col>
                            <ErrorMessage
                              name="mittwoch"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3 row">
                            <Label
                              for="donnerstag"
                              className="col-form-label col-sm-3"
                            >
                              Donnerstag
                            </Label>
                            <Col sm={9}>
                              <Field
                                name="donnerstag"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.donnerstag && touched.donnerstag
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            </Col>
                            <ErrorMessage
                              name="donnerstag"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="mb-3 row">
                            <Label
                              for="freitag"
                              className="col-form-label col-sm-3"
                            >
                              Freitag
                            </Label>
                            <Col sm={9}>
                              <Field
                                name="freitag"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.freitag && touched.freitag
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            </Col>
                            <ErrorMessage
                              name="freitag"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="mb-3 row">
                            <Label
                              for="samstag"
                              className="col-form-label col-sm-3"
                            >
                              Samstag
                            </Label>
                            <Col sm={9}>
                              <Field
                                name="samstag"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.samstag && touched.samstag
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            </Col>
                            <ErrorMessage
                              name="samstag"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3 row">
                            <Label
                              for="sonntag"
                              className="col-form-label col-sm-3"
                            >
                              Sonntag
                            </Label>
                            <Col sm={9}>
                              <Field
                                name="sonntag"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.sonntag && touched.sonntag
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            </Col>
                            <ErrorMessage
                              name="sonntag"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3 row">
                            <Label
                              for="vacation"
                              className="col-form-label col-sm-3"
                            >
                              Betriebsurlaub
                            </Label>
                            <Col sm={9}>
                              <div className="input-group">
                                <Flatpickr
                                  name="vacation"
                                  className="form-control d-block"
                                  onChange={e => setVacationDates(e)}
                                  value={vacationDates}
                                  placeholder="Wählen Sie die freien Tage aus"
                                  options={{
                                    mode: "multiple",
                                    dateFormat: "d.m.y",
                                    minDate: "today",
                                    locale: {
                                      ...Austria,
                                      firstDayOfWeek: 1,
                                    },
                                  }}
                                />
                              </div>
                            </Col>
                            <ErrorMessage
                              name="vacation"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="text-center mt-4">
                            <Button type="submit" color="primary">
                              Speichern
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Loading>
        </Container>
      </div>
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  resetProfileFlag: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStateToProps, { editProfile, resetProfileFlag })(UserProfile)
);
