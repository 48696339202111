import firebase from "firebase/compat/app";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import _ from "lodash";
import moment from "moment";

class FirebaseAuthBackend {
  db;
  storage;
  authErrors;

  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      const app = firebase.initializeApp(firebaseConfig);

      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          localStorage.setItem("authUser", JSON.stringify(user));
        } else {
          localStorage.removeItem("authUser");
        }
      });
      this.db = firebase.firestore();
      this.storage = firebase.storage();

      this.authErrors = [
        "auth/invalid-email",
        "auth/user-not-found",
        "auth/wrong-password",
      ];
    }
  }

  /**
   * upload Logo
   */
  uploadLogo = async (file, companyName) => {
    const storageRef = ref(this.storage, `logos/${companyName}/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);

    return await getDownloadURL(snapshot.ref);
  };

  /**
   * get company
   */
  getCompany = _ => {
    return new Promise((resolve, reject) => {
      const user = this.getAuthenticatedUser();
      firebase
        .firestore()
        .collection("companies")
        .doc(user.uid)
        .get()
        .then(
          doc => {
            resolve({ id: doc.id, ...doc.data() });
          },
          error => {
            console.log(error);
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * get company resolve({ id: doc.id, ...doc.data() });
   */
  getCompanyByName = companyName => {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("companies")
        .where("name", "==", companyName?.toLowerCase())
        .get()
        .then(
          querySnapshot => {
            const result = [];
            querySnapshot.forEach(doc => {
              result.push({ id: doc.id, ...doc.data() });
            });

            resolve(result[0]);
          },
          error => {
            console.log(error);
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Update Comapny
   */
  updateCompany = company => {
    const user = this.getAuthenticatedUser();

    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("companies")
        .doc(user.uid)
        .update(
          removeUndefined({
            name: company.name,
            logo: company.logo,
            color: company.color,
            tables: company.tables,
            background: company.background,
            website: company.website,
            openingHours: company.openingHours,
            vacation: company.vacation,
            lastModified: new Date(),
          })
        )
        .then(
          _ => {
            resolve(company);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Add reservation
   */
  addReservation = async (reservation, companyID) => {
    if (!companyID) companyID = this.getAuthenticatedUser()?.uid;

    const docRef = firebase.firestore().collection("companies").doc(companyID);

    reservation = _.omitBy(reservation, _.isNil);

    return new Promise((resolve, reject) => {
      docRef.get().then(
        doc => {
          const data = doc.data();
          const foundReservations = this.getDuplicatedReservations(
            data.reservations,
            reservation
          );

          if (foundReservations.length == 0) {
            this.getNextReservationID(companyID).then(nextID =>
              docRef
                .update({
                  reservations: firebase.firestore.FieldValue.arrayUnion({
                    id: nextID,
                    ...reservation,
                  }),
                })
                .then(
                  _ => resolve(reservation),
                  error => this.internalError(reject)
                )
            );
          } else this.duplicateError(reject);
        },
        error => this.internalError(reject)
      );
    });
  };

  getDuplicatedReservations = (reservations, reservation) => {
    const dates = [reservation.date];
    for (let i = 1; i < reservation.duration * 4; i++)
      dates.push(
        moment(reservation.date)
          .add(15 * i, "m")
          .toDate()
      );

    return reservations.filter(r => {
      return (
        dates.filter(d => moment(r.date.toDate()).isSame(d)).length > 0 &&
        r.table == reservation.table &&
        reservation?.id !== r.id
      );
    });
  };

  getNextReservationID = async companyID => {
    const doc = await firebase
      .firestore()
      .collection("companies")
      .doc(companyID)
      .get();

    const obj = doc.data();

    if (obj?.reservations?.length > 0)
      return (Math.max(...obj?.reservations.map(o => o.id)) ?? 0) + 1;

    return 1;
  };

  /**
   * Update Reservations
   */
  updateReservations = (reservation, companyID) => {
    if (!companyID) companyID = this.getAuthenticatedUser()?.uid;

    return new Promise((resolve, reject) => {
      const docRef = firebase
        .firestore()
        .collection("companies")
        .doc(companyID);

      docRef.get().then(
        doc => {
          const data = doc.data();
          const foundReservations = this.getDuplicatedReservations(
            data.reservations,
            reservation
          );

          const reservations = data.reservations.filter(
            r => r.id !== reservation.id
          );

          if (foundReservations.length == 0)
            docRef
              .update({
                reservations: [...reservations, reservation],
              })
              .then(
                _ => resolve(),
                error => this.internalError(reject)
              );
          else this.duplicateError(reject);
        },
        error => this.internalError(reject)
      );
    });
  };

  internalError = reject => {
    console.log(reject);
    reject(
      "Es ist ein unerwarteter Fehler aufgetreten. Bitte probieren Sie es später erneut."
    );
  };

  duplicateError = reject => {
    reject(
      "Es gibt bereits eine Reservierung zu dieser Uhrzeit. Bitte probieren Sie es erneut!"
    );
  };

  /**
   * Delete reservation
   */
  deleteReservation = reservation => {
    const user = this.getAuthenticatedUser();
    console.log(reservation);
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("companies")
        .doc(user.uid)
        .update({
          reservations: firebase.firestore.FieldValue.arrayRemove(reservation),
        })
        .then(
          _ => {
            resolve();
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Add new Table
   */
  addNewTable = table => {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("tables")
        .add({
          name: table.name,
          persons: table.persons,
          isOutdoor: table.isOutdoor,
          createdAt: new Date(),
        })
        .then(
          _ => resolve(table),
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Update Table
   */
  updateTable = table => {
    return new Promise((resolve, reject) => {
      table.lastModified = new Date();

      firebase
        .firestore()
        .collection("tables")
        .doc(table.id)
        .set({
          name: table.name,
          persons: table.persons,
          isOutdoor: table.isOutdoor,
          lastModified: table.lastModified,
          createdAt: table.createdAt,
        })
        .then(
          _ => {
            resolve(table);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * delete Table
   */
  deleteTable = table => {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("tables")
        .doc(table.id)
        .delete()
        .then(
          _ => {
            resolve(table);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            console.log(error.code);
            if (this.authErrors.includes(error.code))
              reject("E-Mail oder Passwort inkorrekt");
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Social Login user with given details
   */
  socialLoginUser = (data, type) => {
    let credential = {};
    if (type === "google") {
      credential = firebase.auth.GoogleAuthProvider.credential(
        data.idToken,
        data.token
      );
    } else if (type === "facebook") {
      credential = firebase.auth.FacebookAuthProvider.credential(data.token);
    }
    return new Promise((resolve, reject) => {
      if (!!credential) {
        firebase
          .auth()
          .signInWithCredential(credential)
          .then(user => {
            resolve(this.addNewUserToFirestore(user));
          })
          .catch(error => {
            reject(this._handleError(error));
          });
      } else {
        reject(this._handleError(error));
      }
    });
  };

  addNewUserToFirestore = user => {
    const collection = firebase.firestore().collection("users");
    const { profile } = user.additionalUserInfo;
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp(),
    };
    collection.doc(firebase.auth().currentUser.uid).set(details);
    return { user, details };
  };

  setLoggeedInUser = user => {
    localStorage.setItem("authUser", JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

const removeUndefined = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
