import React, { useEffect, useState } from "react";
import { Alert, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { getFirebaseBackend } from "helpers/firebase_helper";
import "flatpickr/dist/themes/material_blue.css";
import { Austria } from "flatpickr/dist/l10n/at.js";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import Loading from "components/Loading";
import { updateFreeTimes } from "helpers/reservation_helper";

const ReservationEditModal = ({
  isOpen,
  toggle,
  company,
  isEdit,
  selectedReservation,
}) => {
  //todo reload times when opening modal (times could be old)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [selectedTime, setSelectedTime] = useState(null);
  const [countPersons, setCountPersons] = useState(2);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("Y-MM-DD")
  );
  const [disabledDates, setDisabledDates] = useState([]);
  const fireBaseBackend = getFirebaseBackend();
  const [freeTimes, setFreeTimes] = useState([]);
  var future = new Date();
  future.setDate(future.getDate() + 30);

  //todo check why all options are selected in dropdown, when one option is selected
  useEffect(() => {
    if (company)
      if (isEdit && selectedReservation) {
        const date = moment(selectedReservation.date.toDate()).format(
          "DD.MM.Y"
        );
        setSelectedDate([selectedReservation.date.toDate()]);
        const time = moment(selectedReservation.date.toDate()).format("H:mm");
        setSelectedTime({ label: time, value: selectedReservation.table });
        setCountPersons(selectedReservation.persons);
        updateFreeTimes(
          selectedReservation.date.toDate(),
          selectedReservation.persons,
          company,
          setDisabledDates,
          setFreeTimes,
          true,
          selectedReservation
        );
      } else
        updateFreeTimes(
          selectedDate,
          countPersons,
          company,
          setDisabledDates,
          setFreeTimes
        );
  }, [isEdit, selectedReservation]);

  const handleSubmit = async values => {
    setLoading(true);
    const reservationDate = new Date(selectedDate);

    reservationDate.setHours(selectedTime?.label?.split(":")[0]);
    reservationDate.setMinutes(selectedTime?.label?.split(":")[1]);

    if (isEdit) {
      selectedReservation = {
        ...selectedReservation,
        table: selectedTime.value,
        name: values.name,
        email: values.email,
        date: reservationDate,
        persons: countPersons,
        notes: values.notes,
      };

      fireBaseBackend
        .updateReservations(selectedReservation, company.id)
        .then(handleSuccess, handleError);
    } else {
      const newReservation = {
        id: selectedReservation.id,
        table: selectedTime.value,
        name: values.name,
        email: values.email,
        date: reservationDate,
        persons: countPersons,
        notes: values.notes,
        duration: values.duration,
        bookedByUser: false,
      };

      fireBaseBackend
        .addReservation(newReservation)
        .then(handleSuccess, handleError);
    }
  };

  const handleSuccess = _ => {
    toggleModal();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleError = error => {
    console.log(error);
    // setError(error);
    setLoading(false);
    setTimeout(() => {
      setError();
    }, 3000);
  };

  const toggleModal = () => {
    if (isOpen) {
      setTimeout(() => {
        setSelectedDate();
        setSelectedTime();
        setCountPersons(2);
      }, 1000);
    }
    toggle();
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggleModal}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleModal}>Reservierung Details</ModalHeader>
          <ModalBody>
            <Loading isLoading={loading}>
              {error && <Alert color="danger">{error}</Alert>}
              <Formik
                enableReinitialize={true}
                initialValues={{
                  email: selectedReservation?.email ?? "",
                  name: selectedReservation?.name ?? "",
                  notes: selectedReservation?.notes ?? "",
                  duration: selectedReservation?.duration ?? 1,
                  persons: selectedReservation?.persons ?? 2,
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Bitte geben Sie Ihren Namen an"),
                })}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form className="form-horizontal">
                    <div className="mb-3">
                      <Label for="persons" className="form-label">
                        Wie viele Personen?
                      </Label>
                      <Field
                        name="persons"
                        type="number"
                        onChange={e => {
                          setCountPersons(e.target.value);
                          updateFreeTimes(
                            selectedDate,
                            e.target.value,
                            company,
                            setDisabledDates,
                            setFreeTimes
                          );
                        }}
                        value={countPersons}
                        className={
                          "form-control" +
                          (errors.persons && touched.persons
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="persons"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="date" className="form-label">
                        An welchem Datum?
                      </Label>

                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd.mm.yyyy"
                        onChange={e => {
                          setSelectedDate(e);
                          updateFreeTimes(
                            e,
                            countPersons,
                            company,
                            setDisabledDates,
                            setFreeTimes
                          );
                        }}
                        value={selectedDate}
                        options={{
                          dateFormat: "d.m.y",
                          minDate: "today",
                          maxDate: future,
                          disable: disabledDates,
                          locale: {
                            ...Austria,
                            firstDayOfWeek: 1,
                          },
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Welche Uhrzeit?</Label>
                      <Select
                        name="selectedTime"
                        noOptionsMessage={() =>
                          "Zu dieser Uhrzeit keine Tische verfügbar."
                        }
                        value={selectedTime}
                        onChange={e => {
                          setSelectedTime(e);
                        }}
                        options={freeTimes}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="name" className="form-label">
                        Name
                      </Label>
                      <Field
                        name="name"
                        type="text"
                        className={
                          "form-control" +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="email" className="form-label">
                        Email
                      </Label>
                      <Field
                        name="email"
                        type="email"
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="notes" className="form-label">
                        Notizen
                      </Label>
                      <Field
                        name="notes"
                        type="text"
                        className={
                          "form-control" +
                          (errors.notes && touched.notes ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="notes"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="mt-4 d-grid">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        Speichern
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Loading>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ReservationEditModal;
