import React, { Component, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";

let series = [
  {
    name: "Mitarbeiterbuchung",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  {
    name: "Kundenbuchung",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
];

const options = {
  chart: {
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
  },
  colors: ["#556ee6", "#f1b44c"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [20, 100, 100, 100],
    },
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mär",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez",
    ],
  },

  markers: {
    size: 3,
    strokeWidth: 3,

    hover: {
      size: 4,
      sizeOffset: 2,
    },
  },
  legend: {
    position: "top",
    horizontalAlign: "right",
  },
};

const CardUser = ({ company }) => {
  const cardreport = {
    options: options,
    series: series,
  };

  company.reservations
    ?.filter(r => r.date?.toDate()?.getFullYear() == new Date().getFullYear())
    .forEach(r => {
      if (r.bookedByUser) series[1].data[r.date.toDate().getMonth()]++;
      else series[0].data[r.date.toDate().getMonth()]++;
    });

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              <h5 className="card-title me-2">Reservierungen</h5>
            </div>

            <Row className="text-center">
              <Col lg={4}>
                <div className="mt-4">
                  <p className="text-muted mb-1">Heute</p>
                  <h5>
                    {company.reservations?.filter(
                      r =>
                        r.date.toDate().toDateString() ==
                        new Date().toDateString()
                    )?.length ?? 0}
                  </h5>
                </div>
              </Col>

              <Col lg={4}>
                <div className="mt-4">
                  <p className="text-muted mb-1">Letzen 30 Tage</p>
                  <h5>
                    {company.reservations?.filter(r => {
                      const days =
                        Math.abs(
                          new Date().getTime() - r.date.toDate().getTime()
                        ) /
                        (24 * 60 * 60 * 1000);
                      return days < -30 && days > 0;
                    })?.length ?? 0}
                  </h5>
                </div>
              </Col>

              <Col lg={4}>
                <div className="mt-4">
                  <p className="text-muted mb-1">Dieses Jahr</p>
                  <h5>
                    {company?.reservations?.filter(
                      r =>
                        r.date.toDate().getFullYear() ==
                        new Date().getFullYear()
                    )?.length ?? 0}{" "}
                  </h5>
                </div>
              </Col>
            </Row>

            <hr className="mb-4" />
            <div className="apex-charts" id="area-chart" dir="ltr">
              <ReactApexChart
                options={cardreport.options}
                series={cardreport.series}
                type="area"
                height={350}
                className="apex-charts"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default CardUser;
