import moment from "moment";

var future = new Date();
future.setDate(future.getDate() + 30);

export const updateFreeTimes = (
  date,
  persons,
  company,
  setDisabledDates,
  setFreeTimes,
  isEdit = false,
  selectedReservation
) => {
  const tablesWithCorrectSize = company?.tables
    .filter(t => t.persons >= persons)
    ?.sort((a, b) => a.persons - b.persons)
    ?.map(t => t.name);

  if (!tablesWithCorrectSize?.length > 0) {
    setFreeTimes([]);
    setDisabledDates(getDates(new Date(), future));
  } else {
    const tableNames = tablesWithCorrectSize;

    let reservations = company.reservations?.filter(
      r =>
        tableNames.includes(r.table) &&
        getDateString(r.date) === getDateString(new Date(date))
    );

    //if edit mode also remove times from the reservation which is edited
    //to be able to change the time of the reservation
    if (isEdit)
      reservations = reservations.filter(
        r =>
          !(
            r.table == selectedReservation.table &&
            moment(r.date.toDate()).isSame(selectedReservation.date.toDate())
          )
      );

    const availableTimes = calculateFreeTimes(
      company,
      tableNames,
      reservations,
      date
    );

    setFreeTimes([
      {
        options: availableTimes,
      },
    ]);
    calculateDisabledDates(company, setDisabledDates);
  }
};

const calculateDisabledDates = (company, setDisabledDates) => {
  const result = company.vacation?.map(v => v.toDate()) ?? [];
  const next30Days = getDates(new Date(), future);
  const freeDaysIdx = [];

  company.openingHours?.forEach((e, idx) => {
    if (!e || e === "") freeDaysIdx.push(idx + 1);
  });

  if (freeDaysIdx.length > 0)
    next30Days.forEach(d => {
      if (freeDaysIdx.includes(getDayFromDate(d))) result.push(d);
    });

  setDisabledDates(result);
};

const getDayFromDate = date => {
  if (date.getDay() === 0) return 7;
  return date.getDay();
};

const getDates = (startDate, endDate) => {
  const dates = [startDate];
  let currentDate = startDate;
  const addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    currentDate = addDays.call(currentDate, 1);
    dates.push(currentDate);
  }
  return dates;
};

const calculateFreeTimes = (company, tables, reservations, date) => {
  const availableTimes = [];

  //calculate free time for each table
  tables.forEach(table => {
    const dayOfWeek = new Date(date).getDay();
    const openHours = company.openingHours[dayOfWeek - 1]?.split(";");

    if (openHours && companyIsOpen(openHours))
      openHours?.forEach(openHour => {
        let times = slots(openHour.split("-")[0], openHour.split("-")[1]);
        const res = reservations.filter(r => r.table === table);

        //remove reserved times for this table
        res.forEach(r => {
          times = removeAlreadyBookedTimes(r, times);
        });

        //add the to the availableTimes if not exists
        times.forEach(t => {
          if (availableTimes.filter(a => a.label === t).length === 0)
            availableTimes.push({ label: t, value: table });
        });
      });
  });

  return availableTimes;
};

const toMinutes = str => str.split(":").reduce((h, m) => h * 60 + +m);

const minToString = min => {
  const m = min % 60;
  const h = (min - m) / 60;

  return (
    (h < 10 ? "0" : "") +
    h.toString() +
    ":" +
    (m < 10 ? "0" : "") +
    m.toString()
  );
};

function slots(startStr, endStr = "16:00") {
  let start = toMinutes(startStr);
  let end = toMinutes(endStr);
  return Array.from({ length: Math.floor((end - start) / 30) + 1 }, (_, i) =>
    minToString(start + i * 30)
  );
}

const getDateString = date => {
  if (!date) return "";
  if (!(date instanceof Date) && typeof date.toDate == "function")
    date = new Date(date.toDate());
  const date1 = moment(date).format("DD MMM Y");
  return date1;
};

const removeAlreadyBookedTimes = (reservation, times) => {
  const dates = getReservationDurationTimes(reservation);

  return times.filter(t => !dates.includes(t));
};

const getReservationDurationTimes = reservation => {
  const dates = [reservation.date.toDate()];

  for (let i = 1; i <= reservation.duration * 2; i++)
    dates.push(
      moment(reservation.date.toDate())
        .add(30 * i, "m")
        .toDate()
    );
  return dates.map(
    d => padTo2Digits(d.getHours()) + ":" + padTo2Digits(d.getMinutes())
  );
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

const companyIsOpen = openHours => {
  return openHours?.length !== 0 && openHours[0] !== "";
};
