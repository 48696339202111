import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getFirebaseBackend } from "helpers/firebase_helper";
import DeleteModal from "components/Common/DeleteModal";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ReservationEditModal from "./ReservationEditModal";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
const Reservations = _ => {
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [company, setCompany] = useState();
  const [selectedReservation, setSelectedReservation] = useState();
  const fireBaseBackend = getFirebaseBackend();
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const ReservationsColumns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "table",
      text: "Tisch",
    },
    {
      dataField: "persons",
      text: "Plätze",
    },
    {
      dataField: "date",
      text: "Datum",
      sort: true,
      formatter: (_, row) => handleValidDate(row.date),
    },
    {
      dataField: "notes",
      text: "Notizen",
      sort: true,
      formatter: (_, row) => <>{row.notes.substr(0, 40)}</>,
    },
    {
      dataField: "email",
      text: "E-Mail",
      formatter: (_, row) => (
        <Link to={"mailto:" + row.email}>{row.email}</Link>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Aktion",
      width: "5",
      formatter: (_, reservation) => (
        <>
          <div className="d-flex gap-3">
            <Link to="#" className="text-success">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={() => handleReservationClick(reservation)}
              />
            </Link>
            <Link to="#" className="text-danger">
              <i
                className="mdi mdi-delete font-size-18"
                id="deletetooltip"
                onClick={() => onClickDelete(reservation)}
              />
            </Link>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      await loadCompany();
      setLoading(false);
    })();
  }, []);

  const loadCompany = async _ => {
    const c = await fireBaseBackend.getCompany();
    setCompany(c);
  };

  const toggleEditModal = async () => {
    setEditModal(!editModal);

    if (editModal) {
      setLoading(true);
      await loadCompany();
      setLoading(false);
    }
  };

  const handleNewReservationClick = () => {
    setSelectedReservation({ persons: 2, name: "", email: "" });
    setIsEdit(false);
    toggleEditModal();
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const onClickDelete = reservation => {
    setSelectedReservation(reservation);
    setDeleteModal(true);
  };

  const handleDeleteReservation = async () => {
    setDeleteModal(false);
    setLoading(true);
    delete selectedReservation.id;
    await fireBaseBackend.deleteReservation(selectedReservation);
    await loadCompany();
    setLoading(false);
  };

  const handleReservationClick = arg => {
    setSelectedReservation(arg);
    setIsEdit(true);
    toggleEditModal();
  };

  const handleValidDate = date => {
    if (!date) return "";
    if (!(date instanceof Date) && typeof date.toDate == "function")
      date = new Date(date.toDate());
    const date1 = moment(date).format("DD MMM Y, H:mm");
    return date1;
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: company?.Reservations?.length || 0,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "date",
      order: "asc",
    },
  ];

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteReservation}
        onCloseClick={toggleDeleteModal}
      />
      <ReservationEditModal
        isOpen={editModal}
        toggle={toggleEditModal}
        company={company}
        isEdit={isEdit}
        selectedReservation={selectedReservation}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Übersicht" breadcrumbItem="Reservierungen" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions || [])}
                    keyField="id"
                    columns={ReservationsColumns || []}
                    data={
                      company?.reservations?.filter(
                        r => r.date.toDate() >= today
                      ) || []
                    }
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={
                          loading || !company?.reservations
                            ? []
                            : company?.reservations?.filter(
                                r => r.date.toDate() >= today
                              )
                        }
                        columns={ReservationsColumns || []}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <Search.SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder="Suche"
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col
                                sm="8"
                                className="d-flex justify-content-end"
                              >
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="btn-rounded mb-2 me-2 "
                                    onClick={async () => {
                                      setLoading(true);
                                      await loadCompany();
                                      setLoading(false);
                                    }}
                                    disabled={!company}
                                  >
                                    <i className="mdi mdi-refresh" />
                                  </Button>
                                </div>
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2 "
                                    onClick={handleNewReservationClick}
                                    disabled={!company}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Neue
                                    Reservierung
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                defaultSorted={defaultSorted}
                                bordered={false}
                                striped={false}
                                classes={"align-middle table-check"}
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => (
                                  <>
                                    {loading ? (
                                      <div className="w-100 d-flex justify-content-center">
                                        <Spinner className="mt-2 mb-2 d-flex" />
                                      </div>
                                    ) : (
                                      <div className="w-100 d-flex justify-content-center">
                                        <p>
                                          Es sind keine Reservierungen vorhanden
                                        </p>
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Reservations);
