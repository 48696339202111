import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

const WelcomeComp = ({ company }) => {
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Willkommen zurück !</h5>
                <p>JUST4BOOKING</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img
                src={profileImg}
                alt=""
                className="img-fluid rounded-circle"
              />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="6">
              <div className="avatar-md profile-user-wid mb-4">
                <span className="avatar-title rounded-circle bg-light overflow-hidden">
                  <img
                    src={company.logo ?? avatar1}
                    alt=""
                    className="w-100 h-100"
                    style={{ objectFit: "cover" }}
                  />
                </span>
              </div>

              <h5 className="font-size-15 text-truncate">{company.name}</h5>
            </Col>

            <Col sm="6">
              <div className="pt-4">
                <div className="mt-4">
                  <Link to="/profil" className="btn btn-primary btn-sm">
                    Profil bearbeiten <i className="mdi mdi-arrow-right ms-1" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default WelcomeComp;
