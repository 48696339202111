import { Link, withRouter } from "react-router-dom";

import * as moment from "moment";

import React, { useEffect, useState } from "react";
import { getFirebaseBackend } from "helpers/firebase_helper";
import DeleteModal from "components/Common/DeleteModal";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Modal,
  Spinner,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Badge,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const Tables = _ => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState();
  const [company, setCompany] = useState();
  const [selectedTable, setSelectedTable] = useState();
  const fireBaseBackend = getFirebaseBackend();

  const TablesColumns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Erstellt am",
      sort: true,
      formatter: (_, row) => handleValidDate(row.createdAt),
    },
    {
      dataField: "lastModified",
      text: "Zuletzt geändert",
      sort: true,
      formatter: (_, row) => handleValidDate(row.lastModified),
    },
    {
      dataField: "nextReservation",
      text: "Nächste Reservierung",
      sort: true,
      formatter: (_, row) => handleValidDate(row.nextReservation),
    },
    {
      dataField: "persons",
      text: "Plätze",
      sort: true,
    },
    {
      dataField: "isFree",
      text: "frei / besetzt",
      sort: true,
      formatter: (cellContent, row) => (
        <Badge
          className={
            "font-size-12 badge-soft-" + row.isFree ? "success" : "danger"
          }
          color={row.isFree ? "success" : "danger"}
          pill
        >
          {row.isFree ? "frei" : "besetzt"}
        </Badge>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Aktion",
      formatter: (cellContent, table) => (
        <>
          <div className="d-flex gap-3">
            <Link to="#" className="text-success">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={() => handleTableClick(table)}
              />
            </Link>
            <Link to="#" className="text-danger">
              <i
                className="mdi mdi-delete font-size-18"
                id="deletetooltip"
                onClick={() => onClickDelete(table)}
              />
            </Link>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    loadCompany();
  }, []);

  const loadCompany = _ => {
    (async () => {
      const c = await fireBaseBackend.getCompany();
      calculateNextReservations(c);
      setCompany(c);
      setLoading(false);
    })();
  };

  const calculateNextReservations = company => {
    const tables = company.tables;
    const now = new Date();
    const reservations = company.reservations
      ?.filter(r => r.date.toDate() > now)
      .sort((a, b) => a.date > b.date);

    tables.forEach(table => {
      const tableReservations = reservations.filter(
        r => r.table === table.name
      );

      if (tableReservations.length > 0) {
        table.nextReservation = tableReservations[0].date.toDate();
        table.isFree =
          tableReservations[0].date.toDate() >
          new Date(now.getTime() + 60 * 60 * 1000 * 1.5);
      } else table.isFree = true;
    });
  };

  const toggle = () => {
    setModal(!modal);
  };

  const handleNewTableClick = () => {
    setSelectedTable({ name: "", persons: 1, isOutdoor: false });
    setIsEdit(false);

    toggle();
  };

  const handleSubmit = selectedTable => {
    const count = company.tables.filter(
      e => e.name === selectedTable.name
    ).length;

    if (
      count > 0 &&
      !(
        isEdit &&
        selectedTable.initialName === selectedTable.name &&
        count == 1
      )
    ) {
      setError("Es gibt bereits einen Tisch mit diesem Namen");
      setTimeout(() => {
        setError();
      }, 2000);
    } else {
      const t =
        company.tables?.filter(e => e.name !== selectedTable.initialName) ?? [];

      company.tables = [
        ...t,
        {
          name: selectedTable.name,
          persons: selectedTable.persons,
          isOutdoor: selectedTable.isOutdoor,
          lastModified: new Date(),
          createdAt: isEdit ? selectedTable.createdAt : new Date(),
        },
      ];

      handleUpdateCompany(company);
      setIsEdit(false);
      setSelectedTable();
      toggle();
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const onClickDelete = table => {
    setSelectedTable(table);
    setDeleteModal(true);
  };

  const handleDeleteTable = async () => {
    if (company) {
      setLoading(true);
      setDeleteModal(false);
      company.tables = company.tables?.filter(
        e => e.name !== selectedTable.name
      );
      await fireBaseBackend.updateCompany(company);
      setLoading(false);
    }
  };

  const handleUpdateCompany = async company => {
    setLoading(true);
    await fireBaseBackend.updateCompany(company);
    loadCompany();
    setLoading(false);
  };

  const handleTableClick = arg => {
    setSelectedTable(arg);
    setIsEdit(true);
    toggle();
  };

  const handleValidDate = date => {
    if (!date) return "";
    if (!(date instanceof Date) && typeof date.toDate == "function")
      date = new Date(date.toDate());
    const date1 = moment(date).format("DD MMM Y, H:mm");
    return date1;
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: company?.tables?.length || 0,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTable}
        onCloseClick={toggleDeleteModal}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Übersicht" breadcrumbItem="Tische" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions || [])}
                    keyField="name"
                    columns={TablesColumns || []}
                    data={company?.tables || []}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="name"
                        data={
                          loading || !company?.tables ? [] : company?.tables
                        }
                        columns={TablesColumns || []}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <Search.SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder="Suche"
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2 "
                                    onClick={handleNewTableClick}
                                    disabled={!company}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Neuer
                                    Tisch
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                defaultSorted={defaultSorted}
                                bordered={false}
                                striped={false}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => (
                                  <>
                                    {loading ? (
                                      <div className="w-100 d-flex justify-content-center">
                                        <Spinner className="mt-2 mb-2 d-flex" />
                                      </div>
                                    ) : (
                                      <div className="w-100 d-flex justify-content-center">
                                        <p>
                                          Es sind noch keine Tische vorhanden
                                        </p>
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                              <Modal isOpen={modal}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {!!isEdit
                                    ? "Tisch bearbeiten"
                                    : "Neuer Tisch"}
                                </ModalHeader>
                                <ModalBody>
                                  <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                      initialName:
                                        (selectedTable && selectedTable.name) ||
                                        "",
                                      name:
                                        (selectedTable && selectedTable.name) ||
                                        "",
                                      persons:
                                        (selectedTable &&
                                          selectedTable.persons) ||
                                        1,
                                      createdAt:
                                        (selectedTable &&
                                          selectedTable.createdAt) ||
                                        new Date(),
                                      isOutdoor:
                                        (selectedTable &&
                                          selectedTable.isOutdoor) ||
                                        false,
                                    }}
                                    validationSchema={Yup.object().shape({
                                      name: Yup.string().required(
                                        "Bitte Name eintragen."
                                      ),
                                    })}
                                    onSubmit={handleSubmit}
                                  >
                                    {({
                                      errors,
                                      values,
                                      touched,
                                      handleChange,
                                    }) => (
                                      <Form>
                                        <Row>
                                          <Col className="col-12">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Name
                                              </Label>
                                              <Field
                                                name="name"
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors.name && touched.name
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name="name"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Anzahl Personen
                                              </Label>
                                              <Field
                                                name="persons"
                                                type="number"
                                                className={
                                                  "form-control" +
                                                  (errors.persons &&
                                                  touched.persons
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name="persons"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <Input
                                                type="checkbox"
                                                className="form-check-input me-2"
                                                id="isOutdoor"
                                                name="isOutdoor"
                                                checked={values.isOutdoor}
                                                onChange={handleChange}
                                              />
                                              <Label
                                                className="form-check-label"
                                                htmlFor="isOutdoor"
                                              >
                                                {"  "}
                                                Tisch befindet sich im
                                                Außenbereich
                                              </Label>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <span className="text-danger">
                                            {error}
                                          </span>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                Speichern
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Form>
                                    )}
                                  </Formik>
                                </ModalBody>
                              </Modal>
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Tables);
