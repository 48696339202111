import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Alert,
  Input,
} from "reactstrap";
import Select from "react-select";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useParams } from "react-router-dom";
import logoImg from "../../assets/images/logo-dark-sm.svg";
import { getFirebaseBackend } from "helpers/firebase_helper";
import Loading from "components/Loading";
import "flatpickr/dist/themes/material_blue.css";
import { Austria } from "flatpickr/dist/l10n/at.js";
import Flatpickr from "react-flatpickr";
import { sendConfirmationMail } from "helpers/mail_helper";
import { updateFreeTimes } from "helpers/reservation_helper";

const Reservation = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [company, setCompany] = useState();
  const [selectedTime, setSelectedTime] = useState(null);
  const [countPersons, setCountPersons] = useState(2);
  const [disabledDates, setDisabledDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const fireBaseBackend = getFirebaseBackend();
  const [freeTimes, setFreeTimes] = useState([
    {
      options: [
        { label: "Bitte Personen und Datum auswählen", value: undefined },
      ],
    },
  ]);
  var future = new Date();
  future.setDate(future.getDate() + 30);

  useEffect(() => {
    (async () => {
      const result = await fireBaseBackend.getCompanyByName(id);
      if (!result) window.location.href = "/404";
      else {
        setCompany(result);
      }
    })();
  }, [id]);

  useEffect(() => {
    if (company) {
      updateFreeTimes(
        selectedDate,
        countPersons,
        company,
        setDisabledDates,
        setFreeTimes
      );
      setLoading(false);
    }
  }, [company]);

  const handleSubmit = async values => {
    setLoading(true);
    const reservationDate = new Date(selectedDate);
    reservationDate.setHours(selectedTime.label.split(":")[0]);
    reservationDate.setMinutes(selectedTime.label.split(":")[1]);

    fireBaseBackend
      .addReservation(
        {
          table: selectedTime.value,
          name: values.name,
          email: values.email,
          date: reservationDate,
          persons: countPersons,
          notes: values.notes,
          duration: values.duration,
          bookedByUser: true,
        },
        company.id
      )
      .then(handleSuccess, handleError);
  };

  const handleSuccess = reservation => {
    sendConfirmationMail(reservation);

    setSuccess(true);
    setLoading(false);
    setTimeout(() => {
      window.location.href = company.website;
    }, 4000);
  };

  const handleError = error => {
    setError(error);
    setLoading(false);
    setTimeout(() => {
      setError();
    }, 4000);
  };

  function handleSelectTime(selectedTime) {
    setSelectedTime(selectedTime);
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <Loading isLoading={loading}>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div
                    className={
                      "bg-soft" + (company?.background ? "" : " bg-primary")
                    }
                    style={{
                      background: company?.background,
                    }}
                  >
                    <Row>
                      <Col className="col-7">
                        <div className="p-4">
                          <h5
                            className={company?.color ? "" : " text-primary"}
                            style={{
                              color: company?.color,
                            }}
                          >
                            Reservierung
                          </h5>
                          <p
                            className={company?.color ? "" : " text-primary"}
                            style={{
                              color: company?.color,
                            }}
                          >
                            Wir freuen uns auf Ihren Besuch
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link
                        to={company?.website ?? "#"}
                        className="ms-auto d-block"
                        style={{ width: "fit-content" }}
                      >
                        <div className="avatar-lg profile-user-wid mb-6">
                          <span className="avatar-title rounded-circle bg-light overflow-hidden">
                            <img
                              src={company?.logo ?? logoImg}
                              alt=""
                              className="w-100 h-100"
                              style={{ objectFit: "cover" }}
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    {success && (
                      <Alert color="success">
                        Ihre Buchung war erfolgreich. Sie werden in kürze
                        weitergeleitet.
                      </Alert>
                    )}
                    {error && <Alert color="danger">{error}</Alert>}

                    <div className="p-2">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email: "",
                          name: "",
                          notes: "",
                          duration: 1,
                          persons: 2,
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().required(
                            "Bitte geben Sie Ihre E-Mail an"
                          ),
                          name: Yup.string().required(
                            "Bitte geben Sie Ihren Namen an"
                          ),
                        })}
                        onSubmit={handleSubmit}
                      >
                        {({ errors, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="persons" className="form-label">
                                Wie viele Personen?
                              </Label>
                              <Field
                                name="persons"
                                type="number"
                                onChange={e => {
                                  setCountPersons(e.target.value);
                                  updateFreeTimes(
                                    selectedDate,
                                    e.target.value,
                                    company,
                                    setDisabledDates,
                                    setFreeTimes
                                  );
                                }}
                                value={countPersons}
                                className={
                                  "form-control" +
                                  (errors.persons && touched.persons
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="persons"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="persons" className="form-label">
                                Welches Datum?
                              </Label>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="dd.mm.yyyy"
                                onChange={e => {
                                  setSelectedDate(e);
                                  updateFreeTimes(
                                    e,
                                    countPersons,
                                    company,
                                    setDisabledDates,
                                    setFreeTimes
                                  );
                                }}
                                value={selectedDate}
                                options={{
                                  dateFormat: "d.m.y",
                                  minDate: "today",
                                  maxDate: future,
                                  disable: disabledDates,
                                  locale: {
                                    ...Austria,
                                    firstDayOfWeek: 1,
                                  },
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">
                                Welche Uhrzeit?
                              </Label>
                              <Select
                                value={selectedTime}
                                onChange={e => {
                                  handleSelectTime(e);
                                }}
                                options={freeTimes}
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="name" className="form-label">
                                Name
                              </Label>
                              <Field
                                name="name"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.name && touched.name
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="email"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="mb-3 d-flex">
                              <Input
                                type="checkbox"
                                className="form-check-input me-2"
                                id="legalnotice"
                                required
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="legalnotice"
                              >
                                Ich habe die{" "}
                                <a
                                  href="https://just4booking.com/datenschutz"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Datenschutzrichtlienen
                                </a>{" "}
                                gelesen und akzeptiere diese.
                              </Label>
                            </div>

                            <div className="mt-4 d-grid">
                              <button
                                className={
                                  "btn btn-block" +
                                  (company?.background ? "" : " btn-primary")
                                }
                                style={{
                                  background: company?.background,
                                  color: company?.color,
                                }}
                                type="submit"
                              >
                                Jetzt Reservieren
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    <a
                      href={company?.website ?? "#"}
                      className="fw-medium text-muted"
                    >
                      {" "}
                      Abbrechen
                    </a>
                  </p>
                  <p>
                    © {new Date().getFullYear()} designed and developed by{" "}
                    <a href="https://just4online.com">JUST4ONLINE</a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Loading>
    </React.Fragment>
  );
};

export default Reservation;
