import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Auth/UserProfile";

// Authentication related pages
import Login from "../pages/Auth/Login";
import Logout from "../pages/Auth/Logout";
import ForgetPwd from "../pages/Auth/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Reservations from "pages/Reservations";
import Tables from "pages/Tables";
import Reservation from "pages/Reservation";
import Calendar from "pages/Calendar";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/reservierungen", component: Reservations },
  { path: "/kalender", component: Calendar },
  { path: "/tische", component: Tables },

  //profile
  { path: "/profil", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: Dashboard },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/passwort-vergessen", component: ForgetPwd },
  { path: "/reservierung/:id", component: Reservation },
];

export { authProtectedRoutes, publicRoutes };
