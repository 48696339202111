import React from "react";
import { Container, Spinner } from "reactstrap";

function Loading(props) {
  return props.isLoading == true ? (
    <Container
      className="justify-content-center d-flex align-items-center"
      style={{ height: "200px", marginTop: "auto", marginBottom: "auto" }}
    >
      <div>
        <Spinner animation="grow" style={{ width: "5em", height: "5em" }} />
      </div>
    </Container>
  ) : (
    <>{props.children}</>
  );
}

export default Loading;
