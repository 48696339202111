import axios from "axios";

const API_URL = "http://localhost:3000/sendConfirmation";
// const API_URL = "https://just4booking.herokuapp.com/sendConfirmation";
const API_KEY = "SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";

const axiosApi = axios.create({
  baseURL: API_URL,
});

const config = {
  headers: { "x-access-token": API_KEY },
};

export const sendConfirmationMail = async reservation => {
  const response = await axiosApi.post(
    API_URL,
    {
      date: reservation.date,
      email: reservation.email,
      name: reservation.name,
      persons: reservation.persons,
    },
    config
  );
};
